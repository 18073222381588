<template>
  <div class="background">
    <div class="modal fade" id="joinModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg">
        <div class="modal-content rounded">
          <div class="mr-2 mt-2">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" class="close-button-modal">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <register> </register>
          </div>
        </div>
      </div>
      <button
        type="button"
        id="dissmisModal"
        class="close-modal"
        data-dismiss="modal"
      ></button>
    </div>
    <top-bar @open="openLogin = true"> </top-bar>
    <main class="d-flex flex-column">
      <div class="p-2">
        <div v-if="sidebarCondition" class="row mx-lg-5 mb-lg-5 mb-5">
          <div class="col-md-3 text-center">
            <div class="center">
              <sidebar> </sidebar>
            </div>
          </div>
          <div class="col-md-8">
            <transition name="fade">
              <router-view />
            </transition>
          </div>
        </div>
        <div v-if="!sidebarCondition">
          <router-view />
        </div>
      </div>
    </main>
    <transition name="fade">
      <div
        v-if="!consentAllowed"
        class="row d-flex justify-content-center align-items-baseline text-center pt-1 cookie-style"
      >
        <div class="py-sm-3 py-md-1 py-lg-1">
          <p>{{ $t("translate.useCookies") }}</p>
        </div>
        <div class="ml-md-2">
          <a class="footer-text-wrappe" href="javascript:void(0)" @click="getCookie()">
            <span class="bolded">{{ $t("translate.clickHere") }}</span>
          </a>
          {{ $t("translate.moreInformation") }}
        </div>
        <div class="ml-1">
          {{ $t("translate.click")
          }}<span class="bolded">“{{ $t("translate.accept") }}”</span>
          {{ $t("translate.consent") }}
        </div>
        <div class="ml-2">
          <button class="my-2 bt-accept-cookies" @click="acceptCookies()">
            {{ $t("translate.accept") }}
          </button>
        </div>
      </div>
    </transition>
    <div class="p-2 footer navbar-fixed-bottom mt-4">
      <footerComponent @open="openLogin = true"> </footerComponent>
    </div>
    <div>
      <div
        v-if="openLogin"
        class="modal fade"
        id="logInModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="logInModalCenterTitle"
      >
        <div class="modal-dialog">
          <div class="modal-content rounded pb-4">
            <login @close="closeLogin()"> </login>
          </div>
        </div>
        <button
          type="button"
          id="dissmisModalLogin"
          class="close-modal"
          data-dismiss="modal"
        ></button>
      </div>
    </div>
    <div
      class="modal fade"
      id="refillModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="refillModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content rounded">
          <span class="text-right"
            ><button
              type="button"
              class="close mt-2"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" class="close-button-modal">&times;</span>
            </button></span
          >
          <refill> </refill>
        </div>
      </div>
    </div>
    <div
      id="forrgotPasswordModal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <resetPassword />
    </div>
  </div>
</template>
<script async src="https://www.googletagmanager.com/gtag/js?id=G-2RST97ZKHH"></script>
<script>
import Refill from "./views/Refill.vue";
import footerComponent from "./components/Footer.vue";
import TopBar from "./components/TopBar";
import Login from "./views/Login.vue";
import Register from "./views/Register.vue";
import Sidebar from "./components/Sidebar.vue";
import ResetPassword from "./views/ResetPasswordRequest";
// import Welcome from './views/Welcome.vue';
export default {
  components: {
    footerComponent,
    TopBar,
    // // Welcome,
    Register,
    Login,
    Sidebar,
    Refill,
    ResetPassword,
  },
  data() {
    return {
      secondStep: false,
      layout: false,
      openLogin: false,
      consentAllowed: false,
    };
  },
  mounted() {
    var match = document.cookie.match(new RegExp("(^| )" + "cookieAllowed" + "=([^;]+)"));
    var googleConsent = localStorage.getItem("consentMode");
    if (match) {
      this.consentAllowed = true;
    } else {
      this.consentAllowed = false;
    }
    if (googleConsent === null) {
      const consentMode = {
        functionality_storage: "denied",
        security_storage: "denied",
        ad_storage: "denied",
        analytics_storage: "denied",
        personalization_storage: "denied",
        ad_user_data: "denied",
        ad_personalization: "denied",
      };
      gtag('consent', 'default', consentMode);
      localStorage.setItem("consentMode", JSON.stringify(consentMode));
    }else{
      gtag('consent', 'default', googleConsent);
    }
  },
  computed: {
    currentRoute() {
      return this.$route.name;
    },
    sidebarCondition() {
      if (
        [
          "CustomerPanel",
          "Refill",
          "TransactionList",
          "Details",
          "TransfersList",
        ].includes(this.currentRoute)
      ) {
        return true;
      } else {
        return false;
      }
    },
  },

  watch: {
    currentRoute() {
      if (["Verify"].includes(this.currentRoute)) {
        this.layout = false;
      } else {
        this.layout = true;
      }
    },
  },
  methods: {
    closeLogin() {
      document.getElementById("dissmisModalLogin").click();
    },
    acceptCookies() {
      const d = new Date();
      d.setTime(d.getTime() + 60 * 24 * 60 * 60 * 1000);
      let expires = "expires=" + d.toUTCString();
      document.cookie = "cookieAllowed" + "=" + "true" + ";" + expires + ";path=/";
      const consentMode = {
        functionality_storage: "granted",
        security_storage: "granted",
        ad_storage: "granted",
        analytics_storage: "granted",
        personalization_storage: "granted",
        ad_user_data: "granted",
        ad_personalization: "granted",
      };
      gtag('consent', 'update', consentMode);
      localStorage.setItem("consentMode", JSON.stringify(consentMode));
      this.consentAllowed = true;
    },
    getCookie() {
      this.isRequestPending = true;
      window.axios
        .get("/api/customers/users/cookiesPDF", { responseType: "blob" })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          let translationName = this.$t("translate.cookies_pdf");
          fileLink.setAttribute("download", translationName);
          document.body.appendChild(fileLink);
          fileLink.click();
        });
    },
  },
};
</script>

<style>
.card {
  top: 10px;
}

.join .rectangle-10 {
  height: 670px;
}

.join .rectangle-register {
  height: 0px;
  top: 0px;
}

.form-control {
  border-radius: 50em !important;
  font-family: "Montserrat", Helvetica;
  font-weight: 400;
  min-width: 300px;
  color: #9e9e9e;
}

.close-modal {
  visibility: hidden;
}

.background {
  background-color: #f4f0ea;
  width: 100%;
  right: 0;
  font-family: "Montserrat", Helvetica;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.navbar-fixed-bottom {
  background: white;
}

.footer-margin {
  margin-top: 300px;
}

.rounded {
  border-radius: 10px !important;
}

main {
  min-height: 80vh;
  height: auto !important;
  height: 100%;
  margin: 0 auto -60px;
}
</style>
