export const translate = {
    top_bar: {
        services: {
            title: "Tipps und Tricks",
            authentication: 'Authentifizierung',
            inter_calling_rates: 'Tarife für internationale Anrufe',
            top_up: 'Aufladung',
        },
        help: {
            title: "Helfen",
            contact_support: "Technische Unterstützung",
            cookie: "Cookie-Richtlinie",
            legal_information: "Rechtsinformation",
            tos: "Nutzungsbedingungen",
            pp: "Datenschutzrichtlinie",
            ib: "Einladungsbonus",
        },
        about_us: {
            title: "Über uns",
            t_elephant: "T-elephant",
            how_it_works: "Wie es funktioniert",
        },
        join: "Verbinden",
        log_in: "Anmelden",
        log_out: "Ausloggen",
        return: "Zurück zum Panel"
    },
    footer: {
        services: {
            title: "Dienstleistungen",
            inter_calling_rates: 'Tarife für internationale Anrufe',
        },
        help: {
            title: "Helfen",
            contact_support: "Technische Unterstützung",
            cookie: "Cookie-Richtlinie",
            legal_information: "Rechtsinformation",
            tos: "Nutzungsbedingungen",
            pp: "Datenschutzrichtlinie",
            ib: "Einladungsbonus",
        },
        about_us: {
            title: "Über uns",
            t_elephant: "T-elephant",
            how_it_works: "Wie es funktioniert",
        },
        product: {
            title: "Produkt",
            easy_calling: "Einfaches Telefonieren",
            calling_rates: "Anruftarife",
        },
        language: {
            title: "Sprache",
            en: "Englisch",
            de: "Deutsch",
            ru: "Russisch",
        },
        log_in: "Anmelden",
        join: "Verbinden",
        return: "Zurück zum Panel",
        log_out: "Ausloggen",
    },
    side_bar: {
        your_accoutn: "Ihr Konto",
        user_profile: "Benutzerprofil",
        billing: "Abrechnung",
        call_history: "Anrufverlauf",
    },
    welcome_page: {
        cheapy_and_easy: "Günstige und einfache internationale Telefongespräche",
        you_are_three: "Sie sind drei Schritte von denen entfernt, die Ihnen in Ihrem Land am nächsten stehen:",
        create_an_account_by: "Erstellen Sie ein Konto, indem Sie Ihre E-Mail-Adresse und optional Ihre Telefonnummer angeben",
        top_up_your_account: "Laden Sie Ihr Konto mit der bequemen Zahlungsmethode auf",
        call_with_authorization: "Anruf mit Autorisierung per PIN oder Telefonnummer",
        creating_account: "Ein Profil erstellen",
        to_set_up_a_call_email: "Um einen Anruf zu tätigen, benötigen Sie lediglich eine E-Mail-Adresse",
        to_set_up_a_call_authorization: "Sie können auch eine Telefonnummer angeben und das Telefonieren ohne Eingabe der PIN-Nummer autorisieren",
        new_account: "Neues Konto",
        phone_number_authorization: "Autorisierung der Telefonnummer",
        if_you_authorize_your_phone: "Wenn Sie Ihre Telefonnummer autorisieren, wird der Anruf nicht getätigt. Sie müssen Ihre PIN eingeben",
        to_perform_authorization: "Um die Autorisierung durchzuführen, melden Sie sich bei Ihrem Konto an, gehen Sie zu den Profileinstellungen und wählen Sie die Autorisierungsmethode aus",
        the_lowest_prices: "Die niedrigsten Preise und Benutzerfreundlichkeit",
        offers_the_cheapest_phone: "T-elephant bietet die günstigsten Telefongespräche in Länder auf der ganzen Welt.",
        no_hidden_fees: "Keine versteckten Gebühren oder die Notwendigkeit, Anwendungen zu installieren.",
        select_the_country_you_want: "Wählen Sie das Land aus, in das Sie telefonieren möchten – prüfen Sie die Preise, laden Sie Ihr Konto auf und rufen Sie an!",
        spend_your_money: " Geben Sie Ihr Geld für das Wesentliche aus, nicht für teure Telefongespräche.",
        international_calling: "Internationale Anrufe",
        international_calling_placeholder: "Wo möchten Sie anrufen?",
        cirrcle_one_first_line: "bester internationaler",
        cirrcle_one_second_line: "anruftarife,",
        cirrcle_one_thrid_line: "beste Qualität zum besten Preis",
        cirrcle_two_first_line: " Sie müssen die App nicht herunterladen,",
        cirrcle_two_second_line: "Verwenden Sie Ihr Telefon – es ist ganz einfach",
        cirrcle_two_thrid_linie: "Und schnell – kommen Sie zu Ihrem",
        cirrcle_two_fourth_line: "Familie und Freunde!",
        cirrcle_three_first_line: "mehr als eine Visitenkarte",
        cirrcle_three_second_line: "Melden Sie sich an, um",
        cirrcle_three_thrid_line: "Extras/Aktionen/Boni/Prämien zu erhalten",
        how_work_title: "Wie telefoniere?",
        how_work_text_point_1: "1. Rufen Sie die Zugangsnummer an ",
        how_work_text_point_2: "2. Geben Sie Ihre PIN ein (wenn Sie Ihre Telefonnummer autorisieren, ist Ihre PIN nicht erforderlich)",
        how_work_text_point_3: "3. Geben Sie Ihre Zielrufnummer ein und genießen Sie günstige Anrufe!",
        Poland: "wenn Sie in Polen sind:",
        Germany: "wenn Sie in Deutschland sind:",
        Great_britan: "wenn Sie in  Großbritannien sind:",
        text_button: "Erfahren Sie mehr",
        world_credits: "Weltweite Credits",
        cheapest: "Günstigste Tarife für Anrufe",
        and: "und",
        show_all_rates: "Alle Tarife anzeigen",
        buy_now: "Kaufe jetzt",
        minutes: "min",
        per_minute: "pro Minute",
    },
    authentication_page: {
        first_title: "Einfach mit Ihrem Telefon autorisieren und genießen!",
        text: "Telephant gewährt Ihnen sicheren Zugriff auf Ihr Konto. Sie können die PIN-Authentifizierung oder die Telefonidentifikation verwenden, um Anrufe einfach über die Rufnummer zu autorisieren. Gehen Sie zum Benutzerprofil und wählen Sie die gewünschte Verwendungsart aus.",
        secondt_title: "Wie lade ich auf? es ist einfach",
        first_step: "Melden Sie sich bei Ihrem T-elephant-Konto an",
        second_step: "Wählen Sie die Schaltfläche Benutzerprofil",
        green_button_text: "Benutzerprofil",
        thrid_step: "Aktivieren Sie die bevorzugte Option (sie wurde grün umrandet)."
    },
    inter_calling_rates_page: {
        text_title_bold: "Tarife für internationale Anrufe",
        text_title: "Probieren Sie internationale Anrufe mit T-elephant aus",
        list_first_el: "Die günstigsten Auslandsgespräche mit hoher Premium-Qualität",
        list_secont_el: "Keine versteckten Gebühren – zahlen Sie nach Bedarf",
        list_third_el: "Keine zusätzliche App installiert werden",
        list_fourth_el: "Kein Internet erforderlich",
        bottom_text_small: "T-elephant bietet Ihnen die Möglichkeit, mit Freunden und Familie auf der ganzen Welt in Kontakt zu bleiben!",
        bottom_text_normal: "Egal von wo aus Sie anrufen – Sie haben Ihre Kosten im Griff!",
        title_select: "Schauen Sie sich unser Angebot an",
        select_placeholder: "Wo möchten Sie anrufen?",
        how_it_works: "Wie es funktioniert",
    },
    top_up_modal: {
        first_name_placeholder: "Vorname",
        last_name_placeholder: "Nachname",
        email_placeholder: "E-mail",
        phone_number_placeholder: "Telefonnummer",
        first_part_tos: "Ich akzeptiereT-Elephant",
        tos: "Nutzungsbedingungen ",
        and_int_tos: "und",
        pp: "Datenschutzrichtlinie",
        button_text: "Aufladen",
        top_up_summary: "Zusammenfassung der Aufladung",
        for_calls: "für Anrufe!",
        refill_value: "Nachfüllwert",
        pay_with_przelewy24: "Bezahlen Sie mit Przelewy24",
        first_part_tos_P24: "Ich erkläre, dass ich das gelesen habe",
        tos_P24: "Vorschriften",
        and_int_tos_p24: "und ",
        pp_P24: "informationspflicht",
        sign_in: "Konto erstellen und akzeptieren",
        service_P24: "Przelewy24-Dienst",
        service_P24_error: "Akzeptieren Sie die Bestimmungen",
        choose_currency: "Bezahlen Sie in Währung",
        transaction_currency: "Zahlungswährung",
        transaction_actual_amount: "Der Betrag in der Zahlungswährung",
        than_you: "Danke schon",
        to_continue_the: "Um den Aufladevorgang fortzusetzen, überprüfen Sie Ihre E-Mail-Adresse und aktivieren Sie Ihr Konto",
        pay_with_stripe: "Bezahlen Sie mit Stripe",
        stripe_service: "Stripe service",
        stripe_auth_user_terms: "Bedingungen für autorisierte Benutzer",
        stripe_service_agree: "Stripe Dienstleistungsvereinbarung",
        select_payment_operator: "Wählen Sie Zahlungsanbieter aus",
        amount_to_pay: "Zu zahlender Betrag: ",
        choose_top_up_size: "Wählen Sie die Aufladegröße",
    },
    contact_support_page: {
        title: "Kontakt mit dem Support",
        header: "Eine E-Mail senden",
        select: {
            placeholder: "Wählen Sie Ihre Option",
            placeholder_calling: "Berufung",
            placeholder_general: "Allgemein",
            placeholder_payments: "Zahlungen",
            placeholder_telebirr: "Telebirr-Übertragung",
            placeholder_product: "Produkt",
            placeholder_reff_bonus: "Empfehlungsbonus",
        },
        email: "E-mail",
        name: "Vorname",
        details: "Einzelheiten",
        button_send: "Schicken",
        button_sending: "Senden",
        sended_title: "Ihre Nachricht wurde erfolgreich gesendet.",
        sended_details: " Detaillierte Informationen wurden an Ihre E-Mail-Adresse gesendet",
        sended_check_response: "Sie können die Antwort unter überprüfen",
        support_system_name: "Telephant-Unterstützungssystem",
        support_using_by: "Nutzung Ihrer E-Mail-Adresse und Ticketnummer.",
    },
    invite_bonus_page: {
        title: "Einladungsbonus",
        text_page_one: "Kann ich kostenlos telefonieren? Sie können noch heute mit T-elephant beginnen! Alles, was Sie tun müssen, ist, Ihren Freunden unseren Service zu empfehlen, sie registrieren sich mit einem Empfehlungscode und Sie erhalten einen Bonus für ihre erste Aufladung!",
        text_page_two: "Auf diese Weise können Sie mit den erhaltenen Mitteln Kontakt zu Ihrer Familie oder Ihren Freunden aufnehmen und noch länger zu den günstigsten Tarifen reden.",
        text_page_three: "Ihren Empfehlungscode finden Sie ganz einfach in Ihrem Profil in der rechten Ecke.",
    },
    t_elephant_page: {
        title: "T-elephant",
        text_page_one: "Wir stehen vor einer globalen Migration als einer neuen Realität: ob Menschen vor etwas fliehen oder auf der Suche nach besseren Chancen und Kulturen sind und jetzt an anderen Orten leben, weit weg von Zuhause und Familie. Unsere Mission ist es, die Möglichkeit zu geben, Kontakte zu Familie und Freunden auf der ganzen Welt zu pflegen.",
        text_page_two: "T-elephant ist eine Lösung für alle, die eine günstige Tagesverbindung suchen. Wir haben ein Produkt geschaffen, das Technologie und Erfahrung vereint, um die Kosten für Auslandsgespräche auf akzeptable Tarife zu senken, sodass die alltägliche Kommunikation das Haushaltsbudget nicht belastet. Ziel war es, internationale Anrufe per Telefon zu ermöglichen, ohne dass zusätzliches Internet oder Anwendungen genutzt werden müssen. Einfach, schnell, günstig und von höchster Qualität. T-elephant ist eine Möglichkeit zur internationalen Kommunikation für alle, die anrufen, um mit ihren Freunden und Familie weltweit in Kontakt zu treten.",
        text_page_three: "",
        company_details: "Firmendetails",
    },
    how_it_works_page: {
        poland: "Polen",
        england: "England",
        german: "Deutschland",
        title: "Internationale Aufladung",
        text_under_title_one: "Service für internationale Anrufe / für internationale Anrufer.",
        call_any_destination: "Rufen Sie jedes Ziel zum niedrigsten Preis an und kontrollieren Sie Ihre Ausgaben.",
        text_under_title_two: "KEINE VERSTECKTEN KOSTEN.",
        text_under_title_three: "Noch nie war das Telefonieren so schnell, günstig und einfach.",
        first_step: "Wählen Sie die T-elephant-Zugangsnummer (der Anruf wird gemäß dem Tarif des Betreibers bezahlt).",
        scond_step: "Geben Sie die PIN-Nummer ein, die Sie bei der Registrierung erhalten haben (oder erlauben Sie die Autorisierung per Telefonnummer).",
        thrid_step: "Geben Sie die Zielrufnummer ein und genießen Sie lange Gespräche.",
        second_tab_title: "Registrieren Sie sich und erhalten Sie zusätzliche Boni!",
        second_tab_text: "Empfehlen Sie den T-elephant Ihren Freunden, holen Sie sich Ihr Cashback für die Aufladung und können Sie damit noch länger völlig kostenlos telefonieren!",
        thrid_tab_title: "Laden Sie ungenutztes Guthaben für Ihre Familie auf!",
        thrid_tab_text: "Mit T-elephant können Sie Guthaben für Ihre Freunde/Familie/Verwandten. Es ist einfach, günstig und sicher! Genießen Sie es!",
    },
    log_in_modal: {
        title: "Willkommen zurück",
        log_in_with_email: "Melden Sie sich mit Ihrer E-Mail-Adresse an",
        email: "email",
        password: "Passwort",
        forgot_password: "Passwort vergessen?",
        button_text: "Anmeldung",
        log_in_with_pin: "Melden Sie sich mit Ihrer PIN an",
        phone_number: "Telefonnummer",
        pin: "PIN",
        forgot_pin: "PIN vergessen?",
        wrong_email: "Falsche Email oder Passwort!",
        wrong_pin: "Falsche Telefonnummer oder PIN!",
        log_in_email: "Melden Sie sich mit E-Mail an",
    },
    registration_modal: {
        firs_name: "Vorname",
        last_name: "Nachname",
        email: "Email",
        phone_number: "Telefonnummer (freiwillig)",
        password: "Passwort",
        repeat_password: "Passwort wiederholen",
        referal_code: "Referenzcode",
        tos_first_part: "Durch Klicken auf Registrieren akzeptiere ich T-Elephant",
        tos: "Nutzungsbedingungen",
        and: "und  ",
        pp: "Datenschutzrichtlinie",
        register_button: "Registrieren",
        sending_info: "Senden",
        thank_you_tittle: "Danke",
        thank_you_message: "Danke für die Registrierung. Um die Registrierung abzuschließen, überprüfen Sie bitte Ihren E-Mail-Posteingang.",
        enter_country_code: "Geben Sie die Nummer im internationalen Format mit Ländervorwahl ein",
        for: "für",
        postal_code: "Postleitzahl",
        house_number: "Hausnummer",
        street: "Stadt und Straße",
        Country: "Land",
    },
    your_account_page: {
        title: "IHR KONTO",
        top_up: "Aufladen",
        acc_balance: "Kontostand",
        reff_code: "Referenzcode",
        other: "andere",
        write_value: "Geben Sie den Aufladebetrag ein",
        ref_code_balance: "Zusätzliche Mittel aus dem Empfehlungscode nur für Anrufe",
    },
    user_profile_page: {
        title: "BENUTZERPROFIL",
        decription: "Sehen und aktualisieren Sie Ihre Daten gleich hier. Verwalten Sie hier Ihre Anmeldeoptionen und Passwörter.",
        edit: "Bearbeiten",
        name: "Name",
        search: "Search",
        language: "Sprache",
        english: "Englisch",
        your_email: "Ihre E-Mail",
        pin_authentication: "PIN-Authentifizierung",
        phone_indentification: "Telefonidentifikation",
        mobile_number: "Handynummer",
        your_pin: "Ihre PIN",
        enabled: "ermöglicht",
        disabled: "deaktiviert",
        update_details: {
            title: "Aktualisieren Sie meine Daten",
            name: "Name",
            language: "Sprache",
            email: "E-Mail-Adresse",
            mobile: "Handynummer",
            password: "Ihr Passwort",
            pin: "Ihr PIN",
            save: "Speichern",
            loading: "Laden ...",
            change_language: "Ändern Sie Ihre Sprache",
            english: "Englisch",
            polish: "Polnische ",
            german: "Deutsch",
            your_profile_has: "Ihr Profil wurde aktualisiert",
        },
        en: "Englisch",
        pl: "Polnische ",
        de: "Deutsch",
    },
    billing_page: {
        Card: "Karte",
        Refill: "Aufladen www",
        title: "Abrechnung",
        from_number: "Von Nummer",
        please_wait: " Bitte warten Sie, während wir Ihre Anfrage bearbeiten",
        search: "Suchen",
        date: "Datum",
        transaction_type: "Art der Transaktion",
        call_time: "Zeit für Anrufe",
        phone_number: "Telefonnummer",
        cost: "Kosten",
        status: "Status",
        balance: "Gleichgewicht",
        empty: "Ihre Abrechnung ist leer",
        from: "aus",
        previous: "Vorherige",
        next: "Nächste",
        Unavailable: "Nicht verfügbar",
        Number_not_allowed: "Nummer nicht erlaubt",
        Daily_limit_for_number_use: "Tageslimit für die Nummernnutzung",
        Daily_usage_limit: "Tägliches Nutzungslimit",
        Lack_of_funds: "Geldmangel",
        Cancelled: "Abgesagt",
        Missed: "Verpasst",
        Busy: "Beschäftigt",
        Received: "Erhalten",
        title_call_history: "Anrufverlauf"
    },
    log_out_modal: {
        message: "Möchten Sie sich wirklich abmelden?",
        yes: "Ja",
        no: "Nein",
    },
    forgot_password_modal: {
        message: "Wenn Sie Ihr Passwort vergessen haben, können Sie es hier zurücksetzen.",
        email: "Deine E-Mail",
        send: "Senden Sie mein Passwort",
        sending: "Senden..",
        meessage_send: "Wenn die angegebene E-Mail-Adresse in unserem Dienst registriert ist, senden wir eine Nachricht mit einem Link zum Zurücksetzen des Passworts.",
    },
    verification_page: {
        failed_title: "Verifizierung fehlgeschlagen!",
        failed_message: "Ihr Bestätigungslink ist entweder abgelaufen oder defekt.",
        succes_title: "Danke schön!",
        succes_message: "Ihr Konto wurde bestätigt",
        wait_for_data: "Bitte warten Sie, während wir Ihre Registrierung überprüfen...",
        log_in: "Anmeldung",
        registration_message: "Vielen Dank für Ihre Registrierung, Ihr Konto wurde bestätigt",
        set_password: "Um den Aufladevorgang fortzusetzen, legen Sie bitte Ihr Passwort fest",
        savePass: "Speichern",
    },
    termsOfService_pdf: "Nutzungsbedingungen.pdf",
    cookies_pdf: "Cookie-Richtlinie.pdf",
    useCookies: "Diese Website verwendet Cookies",
    clickHere: "klicken Sie hier",
    click: "Klicken",
    moreInformation: "für mehr Informationen.",
    accept: "Akzeptieren",
    consent: "um uns Ihr Einverständnis zu erteilen",
    thank_you_page: {
        title: "Vielen Dank fürs Auffüllen",
        notification: "Ihr Geld ist gerade auf Ihrem Konto eingetroffen",
        wait_for_payment: "Ihr Geld wird bald auf Ihrem Konto eingehen. Bitte haben Sie etwas Geduld. Bitte aktualisieren Sie die Seite gleich, um den Zahlungsstatus zu überprüfen. Wenn Sie Fragen haben, kontaktieren Sie uns bitte",
        error_title: "Es tut uns leid, dass ein Problem aufgetreten ist",
        error_notification: "Sieht so aus, als wäre etwas völlig schief gelaufen! Bitte kontaktieren Sie uns",
        wait_for_data: "Bitte warten Sie",
    },
    password_reset_page: {
        loading: "Bitte warten Sie, während wir Ihre Anfrage bearbeiten...",
        reset_password: "Passwort zurücksetzen",
        password_changed: "Passwort geändert!",
        you_can_log_in: "Ihr Passwort wurde erfolgreich geändert. Sie können sich jetzt anmelden.",
        verification_failed: "Verifizierung fehlgeschlagen!",
        failed_info: "Ihr Link zum Zurücksetzen des Passworts ist entweder abgelaufen oder defekt.",
    }
}