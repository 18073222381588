export default {
    logedSatus(state, data){
        state.logedSatus = data;
    },
    amount(state, data){
        state.amount = data;
    },
    otherValue(state, data){
        state.otherValue = data
    },
    showOther(state, data){
        state.showOther = data
    },
    userInfo(state, data){
        state.userInfo = data
    },
    card(state, data){
        state.card = data
    }
}