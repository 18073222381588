export const translate = {
        top_bar: {
            services: {
                title: "Porady i wskazówki",
                authentication: 'Uwierzytelnianie',
                inter_calling_rates: 'Połączenia międzynarodowe',
                top_up: 'Doładowanie',
            },
            help: {
                title: "Pomoc",
                contact_support: "Skontaktuj się z pomocą techniczną",
                cookie: "Polityka cookies",
                legal_information: "Informacje prawne",
                tos: "Regulamin",
                pp: "Polityka prywatności",
                ib: "Bonus za zaproszenie",
            },
            about_us: {
                title: "O nas",
                t_elephant: "T-elephant",
                how_it_works: "Jak to działa",
            },
            join: "Zarejestruj się",
            log_in: "Zaloguj się",
            log_out: "Wyloguj się",
            return: "Powrót do panelu"
        },
        footer: {
            services: {
                title: "Usługi",
                inter_calling_rates: 'Ceny połączeń międzynarodowych',
            },
            help: {
                title: "Pomoc",
                contact_support: "Kontakt z pomocą techniczną",
                cookie: "Polityka cookies",
                legal_information: "Informacje prawne",
                tos: "Regulamin",
                pp: "Polityka prywatności",
                ib: "Poleć znajomego",
            },
            about_us: {
                title: "O nas",
                t_elephant: "T-elephant",
                how_it_works: "Jak to działa",
            },
            product: {
                title: "Produkty",
                easy_calling: "Łatwe dzwonienie",
                calling_rates: "Stawki za połączenia",
            },
            language: {
                title: "Język",
                en: "Angielski",
                de: "Niemiecki",
                ru: "rosyjski",
                pl: "Polski",
            },
            log_in: "Zaloguj się",
            join: "Zarejestruj się",
            return: "Wróć do panelu",
            log_out: "Wyloguj się",
        },
        side_bar: {
            your_accoutn: "Twoje konto",
            user_profile: "Profil użytkownika",
            billing: "Bilingi",
            call_history: "Historia połączeń",
        },
        welcome_page: {
            cheapy_and_easy: "Tanie i proste międzynarodowe rozmowy telefoniczne",
            you_are_three: "Trzy kroki dzielą Cię od najbliższych w Twoim kraju:",
            create_an_account_by: "załóż konto, podając swój adres e-mail i opcjonalnie numer telefonu",
            top_up_your_account: "doładuj konto wygodną formą płatności",
            call_with_authorization: "dzwoń autoryzując sie przez PIN lub numer telefonu",
            creating_account: "Zakładanie konta",
            to_set_up_a_call_email: "Do załozenia konta wystarczy adres e-mail",
            to_set_up_a_call_authorization: "Możesz również podać numer telefonu i zautorzywoać go do wykonywania połączeń bez wpiswyania numeru PIN",
            new_account: "Nowe konto",
            phone_number_authorization: "Autoryzacja numeru telefonu",
            if_you_authorize_your_phone: "Jeśli autoryzujesz swój numer telefonu do wykonania połączenia nie będzie potrzebne wprowadzanie nuermu PIN.",
            to_perform_authorization: "Aby przeprowadzić autoryzację, zaloguj się na swoje konto i przejdź do profilu i wybierz metodę autoryzacji",
            the_lowest_prices: "Najniższe ceny i wygoda użytkowania",
            offers_the_cheapest_phone: "T-elephant oferuje najtańsze połączenia telefoniczne do krajów na całym świecie",
            no_hidden_fees: "Brak ukrytych opłat i konieczności instalowania aplikacji",
            select_the_country_you_want: "Wybierz kraj, do którego chcesz zadzwonić - sprawdź ceny, doładuj konto i dzwoń!",
            spend_your_money: "Wydawaj pieniądze na to, co ważne, a nie na drogie rozmowy telefoniczne",
            international_calling: "Wybierz, gdzie chcesz zadzwonić i sprawdź nasze ceny:",
            international_calling_placeholder: "Gdzie chcesz zadzwonić?",
            cirrcle_one_first_line: "najlepsze międzynarodowe",
            cirrcle_one_second_line: "stawki za połączenia,",
            cirrcle_one_thrid_line: "najlepsza jakość w najlepszej cenie",
            cirrcle_two_first_line: "Nie musisz pobierać aplikacji",
            cirrcle_two_second_line: "korzystaj z telefonu - to proste",
            cirrcle_two_thrid_linie: "i szybko – dotrzyj do swojego",
            cirrcle_two_fourth_line: "Rodzina i przyjaciele!",
            cirrcle_three_first_line: "więcej niż karta telefoniczna",
            cirrcle_three_second_line: "dołącz, aby zdobyć",
            cirrcle_three_thrid_line: "promocje/bonusy/nagrody",
            how_work_title: "Jak wykonywać połączenia?",
            how_work_text_point_1: "1. Zadzwoń pod numer dostępowy ",
            how_work_text_point_2: "2. Wprowadź swój PIN (jeśli autoryzujesz swój numer telefonu, Twój PIN nie będzie wymagany)",
            how_work_text_point_3: "3. Wpisz swój numer docelowy i ciesz się tanimi połączeniami!",
            Poland: "Jeśli jesteś w Polsce:",
            Germany: "Jeśli jesteś w Niemczech:",
            Great_britan: "Jeśli jesteś w Wielkiej Brytanii:",
            text_button: "Dowiedz się więcej",
            world_credits: "Kredyty świata",
            cheapest: "najtańsze połączenia do  ",
            and: "i",
            show_all_rates: "pokaż wszystkie stawki",
            buy_now: "Kup teraz",
            minutes: "min",
            per_minute: "na minutę",
        },
        authentication_page: {
            first_title: "Autoryzuj się za pomocą telefonu i ciesz się połączeniami!",
            text: "Telephant zapewnia Ci bezpieczny dostęp do Twojego konta. Możesz użyć uwierzytelniania za pomocą kodu PIN lub identyfikacji telefonicznej, aby łatwo autoryzować dzwonienie za pomocą wybranego numeru. Przejdź do profilu użytkownika i wybierz odpowiedni sposób, w jaki chcesz z niego korzystać.",
            secondt_title: "Jak doładować? to proste",
            first_step: "Zaloguj się na swoje konto T-elephant",
            second_step: "Wybierz przycisk Profil użytkownika",
            green_button_text: "Profil użytkownika",
            thrid_step: "Wybierz opcje (zostanie zaznaczona zieloną obwódką)"
        },
        inter_calling_rates_page: {
            text_title_bold: "Stawki za połączenia międzynarodowe",
            text_title: "Wypróbuj połączenia międzynarodowe T-elephant",
            list_first_el: "Najtańsze połączenia międzynarodowe o wysokiej jakości",
            list_secont_el: "Brak ukrytych opłat - płać na bieżąco",
            list_third_el: "Nie ma potrzeby instalowania żadnej dodatkowej aplikacji",
            list_fourth_el: "Internet nie jest potrzebny",
            bottom_text_small: "T-elephant to sposób na pozostanie w kontakcie z przyjaciółmi i rodziną na całym świecie!",
            bottom_text_normal: "Bez względu na to, skąd dzwonisz - kontrolujesz swoje koszty!",
            title_select: "Sprawdź naszą ofertę",
            select_placeholder: "Dokąd chcesz zadzwonić?",
            how_it_works: "Jak to działa",
        },
        top_up_modal: {
            first_name_placeholder: "Imię",
            last_name_placeholder: "Nazwisko",
            email_placeholder: "E-mail",
            phone_number_placeholder: "Numer telefonu",
            first_part_tos: "Akceptuję",
            tos: "Warunki świadczenia usługi",
            and_int_tos: "i",
            pp: "Polityke prywatności",
            button_text: "Doładowanie",
            top_up_summary: "Podsumowanie doładowania",
            for_calls: "na połączenia!",
            refill_value: "Kwota doładowania",
            pay_with_przelewy24: "Przejdź do płatnosći",
            first_part_tos_P24: "Oświadczam, że zapoznałem się z",
            tos_P24: "regulaminem",
            and_int_tos_p24: "i ",
            pp_P24: "obowiązkiem informacyjnym",
            sign_in: "Utwórz konto i zaakcpetuj",
            service_P24: "serwisu Przelewy24",
            service_P24_error: "Zaakceptuj regulamin",
            choose_currency: "Wybierz walutę doładowania",
            transaction_currency: "Waluta płatności",
            transaction_actual_amount: "Kwota w walucie płatności",
            than_you: "Dziękujemy",
            to_continue_the: "Aby kontynuować proces doładowania, sprawdź swój adres e-mail i aktywuj konto",
            pay_with_stripe: "Przejdź do płatności",
            stripe_service: "serwisu Stripe",
            stripe_auth_user_terms: "regulaminem",
            stripe_service_agree: "obowiazkiem informacyjnym Stripe",
            select_payment_operator: "Wybierz Operator płatności",
            amount_to_pay: "Kwota do zapłaty: ",
            choose_top_up_size: "Wybierz wiekość doładowania",
        },
        contact_support_page: {
            title: "Skontaktuj się z pomocą techniczną",
            header: "Wyślij wiadomość e-mail",
            select: {
                placeholder: "Wybierz temat",
                placeholder_calling: "Połączenia",
                placeholder_general: "Ogólne",
                placeholder_payments: "Płatności",
                // placeholder_telebirr: "Telebirr Transfer",
                placeholder_product: "Usługa",
                placeholder_reff_bonus: "Bonus za polecenie",
            },
            email: "E-mail",
            name: "Nazwa",
            details: "Szczegóły",
            button_send: "Wyślij",
            button_sending: "Wysyłanie",
            sended_title: "Twoja wiadomość została pomyślnie wysłana",
            sended_details: "Na Twój adres e-mail została wysłana szczegółowa informacja",
            sended_check_response: "Możesz sprawdzić odpowiedź na",
            support_system_name: "Stronie wsparcia klienta Telephant",
            support_using_by: "Korzystając z adresu e-mail i numeru zgłoszenia.",
        },
        invite_bonus_page: {
            title: "Bonus na zaproszenie",
            text_page_one: "Czy mogę dzwonić za darmo? Możesz zacząć z T-elephant już dziś! Wszystko, co musisz zrobić, to polecić naszą usługę znajomym który, zarejestrują się za pomocą kodu polecającego, a Ty otrzymasz bonus za jego pierwsze doładowanie!",
            text_page_two: "W ten sposób możesz wykorzystać otrzymane środki na skontaktowanie się z rodziną lub przyjaciółmi i jeszcze dłuższą rozmowę po najniższych stawkach",
            text_page_three: "Twój kod polecający możesz łatwo znaleźć po zalogowaniu się w prawym dolnym rogu",
        },
        t_elephant_page: {
            title: "T-elephant",
            text_page_one: "Stoimy w obliczu globalnej migracji jako nowej rzeczywistości: niezależnie od tego, czy ludzie uciekają przed czymś, czy pędzą w kierunku lepszych możliwości, i mieszkamy teraz w różnych miejscach, z dala od domu i rodziny. Naszą misją jest dawanie możliwości podtrzymywania i budowania więzi z rodziną i przyjaciółmi na całym świecie",
            text_page_two: "T-elephant to rozwiązanie dla wszystkich, którzy szukają taniego połączenia dzień po dniu. Stworzyliśmy produkt łączący technologię i doświadczenie, aby obniżyć koszty połączeń międzynarodowych do akceptowalnych stawek, tak aby codzienna komunikacja nie obciążała domowego budżetu. Celem było umożliwienie wykonywania połączeń międzynarodowych za pomocą telefonu bez konieczności korzystania z dodatkowego internetu czy aplikacji. Prosto, szybko, tanio i najwyższej jakości. T-elephant to sposób na międzynarodową komunikację dla każdego, kto dzwoni, aby połączyć się ze swoimi przyjaciółmi i rodziną na całym świecie.",
            text_page_three: "",
            company_details: "Dane Firmy",
        },
        how_it_works_page: {
            poland: 'Polska',
            england: 'Anglia',
            german: "Niemcy",
            title: "Międzynarodowe Doładowania",
            text_under_title_one: "Usługa dedykowana połączeniom międzynarodowym / dla osób dzwoniących z zagranicy.",
            call_any_destination: "Zadzwoń do dowolnego miejsca w najniższej cenie i kontroluj swoje wydatki",
            text_under_title_two: "BRAK UKRYTYCH KOSZTÓW",
            text_under_title_three: "Dzwonienie jeszcze nigdy nie było tak szybkie, tanie i łatwe.",
            first_step: "Wybierz numer dostępowy T-elephant (połączenie jest płatne zgodnie z taryfą operatora)",
            scond_step: "Wprowadź numer PIN, który otrzymałeś podczas rejestracji (lub zezwól na autoryzację przez numer telefonu)",
            thrid_step: "Wprowadź numer docelowy i ciesz się długimi rozmowami",
            second_tab_title: "Zarejestruj się i otrzymaj dodatkowe bonusy!",
            second_tab_text: "Poleć T-elephanta swoim znajomym, odbierz cashback za doładowanie i wykorzystaj go, aby móc rozmawiać jeszcze dłużej całkowicie za darmo!",
            thrid_tab_title: "Doładuj niewykorzystane środki swojej rodzinie!",
            thrid_tab_text: "T-elephant pozwala na doładowanie kredytów znajomym/rodzinie/krewnym. To proste, tanie i bezpieczne!",
        },
        log_in_modal: {
            title: "Witamy ponownie",
            log_in_with_email: "Zaloguj się za pomocą swojego adresu e-mail",
            email: "e-mail",
            password: "Hasło",
            forgot_password: "Nie pamiętasz hasła?",
            button_text: "Zaloguj się",
            log_in_with_pin: "Zaloguj się za pomocą swojego kodu PIN",
            phone_number: "Numer telefonu",
            pin: "PIN",
            forgot_pin: "Nie pamiętasz kodu PIN?",
            wrong_email: "Nieprawidłowy adres e-mail lub hasło!",
            wrong_pin: "Nieprawidłowy telefon lub kod PIN!",
            log_in_email: "Zaloguj się za pomocą adresu e-mail",
        },
        registration_modal: {
            firs_name: "Imię",
            last_name: "Nazwisko",
            email: "E-mail",
            phone_number: "Numer telefonu (opcjonalnie)",
            password: "Hasło",
            repeat_password: "Powtórz hasło",
            referal_code: "Kod polecający",
            tos_first_part: "Naciskając Utwórz konto akceptuję T-Elephant",
            tos: "Warunki świadczenia usług",
            and: "i ",
            pp: "Polityka prywatności",
            register_button: "Utwórz konto",
            sending_info: "Wysyłanie",
            thank_you_tittle: "Dziękuję",
            thank_you_message: "Dziękujemy za rejestrację. Aby dokończyć rejestrację, sprawdź swoją skrzynkę poczty e-mail.",
            enter_country_code: "Wprowadź numer w formacie międzynarodowym z kodem kraju",
            for: "dla",
            postal_code: "Kod Pocztowy",
            house_number: "Ulica i Numer domu",
            city: "Miasto",
            Country: "Kraj"
        },
        your_account_page: {
            title: "TWOJE KONTO",
            top_up: "Doładowanie",
            acc_balance: "Stan konta",
            reff_code: "kod polecający",
            other: "inna kwota",
            write_value: "Podaj kwote doładowania",
            ref_code_balance: "Dodatkowe środki z kodu polecającego tylko na połączenia",
        },
        user_profile_page: {
            title: "PROFIL UŻYTKOWNIKA",
            decription: "Przeglądaj i aktualizuj swoje dane. Tutaj możesz zarządzać opcjami logowania i hasłami.",
            edit: "Edycja",
            name: "Nazwa",
            language: "Język",
            english: "Angielski",
            search: "Szukaj",
            your_email: "Twój adres e-mail",
            pin_authentication: "Uwierzytelnianie za pomocą kodu PIN",
            phone_indentification: "Identyfikacja telefoniczna",
            mobile_number: "Numer telefonu komórkowego",
            your_pin: "Twój pin",
            enabled: "Włączone",
            disabled: "Wyłączony",
            update_details: {
                title: "Zaktualizuj moje dane",
                name: "Nazwa",
                language: "Język",
                email: "Wyślij wiadomość e-mail na adres",
                mobile: "Numer telefonu komórkowego",
                password: "Twoje hasło",
                pin: "Twój pin",
                save: "Zapisz",
                loading: "Ładowanie...",
                change_language: "Zmień swój język",
                english: "Angielski",
                polish: "Polski",
                german: "Niemiecki",
                your_profile_has: "Twój profil został zaktualizowany",
            },
            en: "Angielski",
            pl: "Polski",
            de: "Niemiecki",
        },
        billing_page: {
            Card: "Karta",
            Refill: "Doładowanie www",
            title: "Biling",
            from_number: "Z numeru",
            please_wait: "Proszę czekać, aż przetworzymy Państwa żądanie",
            search: "Szukaj",
            date: "Data",
            transaction_type: "Rodzaj transakcji",
            call_time: "Czas połączenia",
            phone_number: "Numer telefonu",
            cost: "Koszt",
            status: "Status",
            balance: "Saldo",
            empty: "Twoja historia jest pusta",
            from: "od",
            previous: "Poprzedni",
            next: "Następny",
            Unavailable: "Nie dostępny",
            Number_not_allowed: "Numer niedozwolony",
            Daily_limit_for_number_use: "Dzienny limit został wykorzystany",
            Daily_usage_limit: "Dzienny limit został wykorzystany",
            Lack_of_funds: "Brak środków",
            Cancelled: "Anulowane",
            Missed: "Odrzucone",
            Busy: "Zajęte",
            Received: "Odebrane",
            title_call_history: "Historia połączeń",
        },
        log_out_modal: {
            message: "Czy na pewno chcesz się wylogować?",
            yes: "Tak",
            no: "Nie",
        },
        forgot_password_modal: {
            message: "Jeśli zapomniałeś hasła, możesz je zresetować tutaj",
            email: "Twój adres e-mail",
            send: "Wyślij moje hasło",
            sending: "Wysyłanie..",
            meessage_send: "Jeśli podany adres e-mail jest zarejestrowany w naszym serwisie, wyślemy wiadomość z linkiem do resetowania hasła",
        },
        verification_page: {
            failed_title: "Weryfikacja nie powiodła się!",
            failed_message: "Twój link weryfikacyjny wygasł lub jest uszkodzony",
            succes_title: "Dziękuję!",
            succes_message: "Twoje konto zostało zweryfikowane",
            wait_for_data: "Proszę czekać, aż zweryfikujemy Twoją rejestrację...",
            log_in: "Zaloguj się",
            registration_message: "Dziękujemy za rejestracje twoje konto zostało aktywowane",
            set_password: "Aby kontynuować proces doładowania ustaw hasło",
            savePass: "Zapisz",
        },
        termsOfService_pdf: "Regulamin.pdf",
        cookies_pdf: "Plik cookie Policy.pdf",
        useCookies: "Ta strona korzysta z plików cookies",
        clickHere: "Kliknij tutaj",
        click: "Kliknij",
        moreInformation: "aby uzyskać więcej informacji",
        accept: "Akceptuję",
        consent: "aby wyrazić na to swoją zgodę",
        thank_you_page: {
            title: "Dziękujemy za doładowanie",
            notification: "Twoje środki właśnie dotarły na Twoje konto",
            wait_for_payment: "Twoje środki niedługo znajdą się na twoim koncie prosimy o cierpliwość. Proszę odświeżyć stronę za chwilę aby sprawdzić status płatności. Jeśli masz jakieś pytania skontaktuj się z nami",
            error_title: "Przykro nam napotkaliśmy problem",
            error_notification: "Wygląda na to, że coś poszło zupełnie nie tak! Skontaktuj się z nami",
            wait_for_data: "Proszę czekać",
        },
        password_reset_page:{
            loading: "Proszę czekać, przetwarzamy Twoją prośbę...",
            reset_password: "Zresetuj hasło",
            password_changed: "Hasło zmienione!",
            you_can_log_in: "Twoje hasło zostało pomyślnie zmienione. Możesz się teraz zalogować.",
            verification_failed: "Weryfikacja nie powiodła się!",
            failed_info: "Twój link do resetowania hasła wygasł lub jest uszkodzony.",
        }
    }
